import React from 'react'
import { Box } from 'rebass'
import { TextV2 as Text, ICMSTextV2Props } from '../Text/TextV2'

export interface IHeadingWithSupportingCopyProps {
  title?: ICMSTextV2Props
  body?: ICMSTextV2Props[]
  fullWidth?: boolean
  dataTestId?: string
}

const lastItemFormatting = {
  p: {
    marginBottom: '24px',
  },
  'p:last-child': {
    marginBottom: '0',
  },
}

export const HeadingWithSupportingCopy = ({ title, body, fullWidth, dataTestId }: IHeadingWithSupportingCopyProps) => {
  return (
    <Box sx={{ ...lastItemFormatting, ...(fullWidth ? {} : { maxWidth: ['auto', 'auto', 'auto', '450px', '450px'] }) }}>
      {title ? (
        <Text
          tag={title.tag}
          textFontColor={title.textFontColor}
          textFontStyle={title.textFontStyle}
          mobileText={title.mobileText}
          sx={{
            marginBottom: '10px',
            ...title.sx,
          }}
        >
          {title.text}
        </Text>
      ) : null}
      {body
        ? body.map((item, index) => (
            <Text
              key={`textV2-${index}`}
              tag={item.tag}
              textFontStyle={item.textFontStyle}
              textFontColor={item.textFontColor}
              mobileText={item.mobileText}
              sx={{ marginBottom: '30px', ...item.sx }}
              data-test-id={dataTestId}
            >
              {item.text}
            </Text>
          ))
        : null}
    </Box>
  )
}
