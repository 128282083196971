import React from 'react'
import { Box, SxStyleProp, Image } from 'rebass'
import { theme } from '../../../styles'
import { IModuleBackgroundColorProps, IModulePaddingProps, ModuleSection } from '../ModuleSection/ModuleSection'
import { TextV2, ICMSTextV2Props } from '../Text/TextV2'

export interface ILogosBlockProps {
  moduleBackgroundColor: IModuleBackgroundColorProps
  modulePadding: IModulePaddingProps
  eyebrowText?: ICMSTextV2Props
  title: ICMSTextV2Props
  logos: ILogoImageProps[]
  dataTestId: string
}

export interface ILogoImageProps {
  src: string
  altText: string
  order: number
  srcMobile?: string
  sx?: SxStyleProp //for developer use only
}

export function LogosBlock({ eyebrowText, title, logos, ...mobileSectionProps }: ILogosBlockProps): JSX.Element {
  return (
    <ModuleSection {...mobileSectionProps}>
      <Box sx={{ ...theme.v2.utils.flexColumn, alignItems: 'center' }}>
        {eyebrowText && (
          <TextV2
            tag={eyebrowText.tag}
            textFontStyle={eyebrowText.textFontStyle}
            textFontColor={eyebrowText.textFontColor}
            sx={{ textAlign: 'center', marginBottom: '20px', textTransform: 'uppercase', ...eyebrowText.sx }}
          >
            {eyebrowText.text}
          </TextV2>
        )}
        <TextV2
          tag={title.tag}
          textFontColor={title.textFontColor}
          textFontStyle={title.textFontStyle}
          sx={{
            textAlign: 'center',
            marginBottom: '60px',
            ...title.sx,
          }}
        >
          {title.text}
        </TextV2>
        <Box sx={{ ...theme.v2.grids.fiveColumnGrid, columnGap: '100px', rowGap: '35px' }}>
          {logos.map((logo, index) => (
            <Image key={`logo-image-${index}`} src={logo.src} alt={logo.altText} sx={{ ...logo.sx }} />
          ))}
        </Box>
      </Box>
    </ModuleSection>
  )
}
