import { IModuleBackgroundColorProps, ModuleBackgroundColors } from '../ModuleSection/ModuleSection'
import { theme } from '../../../styles'

export const getOuterContainerStyles = (isCopyRight: boolean) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: ['column', 'column', `row${isCopyRight ? '-reverse' : ''}`],
  width: '100%',
})

export const getContainerStyles = (isCopyRight: boolean) => ({
  width: ['100%', '100%', '50%', '50%', '50%'],
  paddingRight: isCopyRight ? [] : [0, 0, 100, 45, 50],
  paddingLeft: isCopyRight ? [0, 0, 100, 45, 50] : [],
  textAlign: ['center', 'center', 'start'],
  marginBottom: ['15px', '15px', 0],
})

export const getBackgroundImageStyles = (backgroundImage?: string, mobileBackgroundImage?: string) => ({
  backgroundImage: [`url('${mobileBackgroundImage}')`, `url('${mobileBackgroundImage}')`, `url('${backgroundImage}')`],
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100%',
})

export const getTitleCopyBackgroundColor = (moduleBackgroundColor: IModuleBackgroundColorProps) => {
  const mobileBackgroundColor =
    theme.v2.colors[
      moduleBackgroundColor.mobile !== ModuleBackgroundColors.white
        ? ModuleBackgroundColors.white
        : ModuleBackgroundColors.copperTint2
    ]
  const desktopBackgroundColor =
    theme.v2.colors[
      moduleBackgroundColor.desktop !== ModuleBackgroundColors.white
        ? ModuleBackgroundColors.white
        : ModuleBackgroundColors.copperTint2
    ]

  return { backgroundColor: [mobileBackgroundColor, mobileBackgroundColor, desktopBackgroundColor] }
}
