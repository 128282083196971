import React from 'react'
import { MouseEvent } from 'react'
import { Box, Image, SxStyleProp, Link } from 'rebass'
import noop from 'lodash/noop'
import { TextV2 as Text, ICMSTextV2Props } from '../Text/TextV2'
import { theme } from '../../../styles/theme'

export interface IContentCardProps {
  title: ICMSTextV2Props
  body: ICMSTextV2Props
  imageSrc: string
  imageAlt: string
  mobileImageSrc?: string
  link?: string
  sx?: SxStyleProp
  onClick?(e: MouseEvent): void
}

export const ContentCard = ({
  title,
  body,
  imageSrc,
  imageAlt,
  mobileImageSrc,
  link,
  sx,
  onClick = noop,
}: IContentCardProps) => {
  const showLinkArrow = !!link || (!!onClick && onClick !== noop)

  const innerCardContents = (
    <Box
      sx={{
        paddingY: '15px',
        display: 'flex',
        flexDirection: ['column'],
        justifyContent: 'space-evenly',
        width: ['60%', '60%', '100%', '100%', '100%'],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Text
          sx={{
            fontFamily: theme.v2.fonts.plantinProSemiBold,
            fontSize: '20px',
            lineHeight: '24px',
            letterSpacing: '-0.16px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '20px',
            color: theme.v2.colors.blackPrimary,
          }}
        >
          {title.text}
        </Text>
        {!!showLinkArrow && (
          <Image
            sx={{
              display: ['none', 'none', 'block', 'block', 'block'],
              height: '12px',
              marginBottom: ['0px', '0px', '5px', '5px', '5px'],
              paddingRight: '20px',
            }}
            src={`/Support-Arrow.png`}
          />
        )}
      </Box>
      <Text
        sx={{
          width: '95%',
          marginBottom: '2px',
          display: ['block', 'block', 'none', 'none', 'none'],
          paddingLeft: '20px',
          fontFamily: theme.v2.fonts.grtskPetaLight,
          color: theme.v2.colors.blackPrimary,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-0.16px',
          marginY: ['5px', '5px', '0px', '0px', '0px'],
        }}
      >
        {body.text}
      </Text>
      <Image
        sx={{
          width: '50px',
          paddingLeft: '20px',
          display: ['block', 'block', 'none', 'none', 'none'],
          marginTop: '5px',
        }}
        src={`/Support-Arrow.png`}
      />
      <Image
        sx={{
          display: ['none', 'none', 'block', 'block', 'block'],
          height: ['0px', '0px', '100px', '159px', '159px'],
        }}
        src={imageSrc}
        alt={imageAlt}
      />
      <Image
        sx={{
          position: 'absolute',
          display: ['block', 'block', 'none', 'none', 'none'],
          height: '124px',
          right: '0px',
        }}
        src={mobileImageSrc || imageSrc}
        alt={imageAlt}
      />
      <Box sx={{ display: 'flex', alignItems: 'flex-end', height: [null, null, null, null, null] }}>
        <Text
          tag={body.tag}
          textFontStyle={body.textFontStyle}
          textFontColor={body.textFontColor}
          sx={{
            width: ['0%', '0%', '95%', '95%', '95%'],
            marginBottom: '2px',
            display: ['none', 'none', 'block', 'block', 'block'],
            marginTop: ['0px', '0px', '30px', '0px', '0px'],
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          {body.text}
        </Text>
      </Box>
    </Box>
  )

  return (
    <Box
      sx={{
        backgroundColor: theme.v2.colors.copperTint3,
        height: ['124px', '124px', '295px', '276px', '276px'],
        width: ['332px', '332px', '210px', '210px', '210px'],
        display: 'flex',
        flexDirection: ['row', 'row', 'column', 'column', 'column'],
        justifyContent: 'space-between',
        position: 'relative',
        maxWidth: 420,
        cursor: showLinkArrow ? 'pointer' : 'default',
        ':hover': showLinkArrow ? { backgroundColor: theme.v2.colors.copperTint2, cursor: 'pointer' } : {},
        ...sx,
      }}
      onClick={onClick}
    >
      {!!showLinkArrow && (
        <Link href={link} sx={{ width: '100%' }}>
          {innerCardContents}
        </Link>
      )}

      {!showLinkArrow && <Box sx={{ width: '100%' }}>{innerCardContents}</Box>}
    </Box>
  )
}
