import React, { PropsWithChildren } from 'react'
import { Box, SxStyleProp } from 'rebass'
import { theme } from '../../../styles/theme'
import { getMargins } from './ModuleSection.styles'

export enum ModuleBackgroundColors {
  white = 'white',
  goldTint1 = 'goldTint1',
  goldTint2 = 'goldTint2',
  goldTint3 = 'goldTint3',
  copperPrimary = 'copperPrimary',
  copperTint2 = 'copperTint2',
  copperTint3 = 'copperTint3',
  copperTint4 = 'copperTint4',
  copperDark = 'copperDark',
  goldTint4 = 'goldTint4',
}

export enum ModulePadding {
  XL = 'XL',
  L = 'L',
  MD = 'MD',
  SM = 'SM',
  XS = 'XS',
  NONE = 'NONE',
}

export enum ModuleMargin {
  XL = 'XL',
  L = 'L',
  MD = 'MD',
  SM = 'SM',
  XS = 'XS',
  NONE = 'NONE',
}

export interface IModuleBackgroundColorProps {
  desktop: ModuleBackgroundColors
  mobile?: ModuleBackgroundColors
}

export interface IModulePaddingProps {
  top: ModulePadding
  bottom: ModulePadding
}

export interface IModuleMarginProps {
  top?: ModuleMargin
  bottom?: ModuleMargin
  left?: ModuleMargin
  right?: ModuleMargin
}

export interface IModuleSectionProps {
  moduleBackgroundColor: IModuleBackgroundColorProps
  modulePadding: IModulePaddingProps
  mobileModulePadding?: IModulePaddingProps // TODO: Refactor to be part of modulePadding interface
  moduleMargin?: IModuleMarginProps
  mobileModuleMargin?: IModuleMarginProps
  dataTestId?: string
  moduleSx?: SxStyleProp
}

export const ModuleSection = ({
  moduleBackgroundColor,
  modulePadding,
  mobileModulePadding,
  moduleMargin,
  mobileModuleMargin,
  dataTestId,
  moduleSx = {},
  children,
}: PropsWithChildren<IModuleSectionProps>) => {
  const { top: paddingTopMobile, bottom: paddingBottomMobile } = mobileModulePadding || {}
  // TODO: Refactor to be part of modulePadding interface
  const mobilePaddingTop = paddingTopMobile
    ? theme.v2.modulePadding[paddingTopMobile]
    : theme.v2.modulePadding[modulePadding.top]
  const mobilePaddingBottom = paddingBottomMobile
    ? theme.v2.modulePadding[paddingBottomMobile]
    : theme.v2.modulePadding[modulePadding.top]

  return (
    <Box
      pt={[mobilePaddingTop, mobilePaddingTop, theme.v2.modulePadding[modulePadding.top]]}
      pb={[mobilePaddingBottom, mobilePaddingBottom, theme.v2.modulePadding[modulePadding.bottom]]}
      bg={[
        theme.v2.colors[moduleBackgroundColor.mobile || moduleBackgroundColor.desktop],
        theme.v2.colors[moduleBackgroundColor.mobile || moduleBackgroundColor.desktop],
        theme.v2.colors[moduleBackgroundColor.desktop],
      ]}
      data-test-id={dataTestId}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        ...getMargins(moduleMargin, mobileModuleMargin),
        ...moduleSx,
      }}
    >
      <Box
        sx={{
          width: ['85%', '90%', 750, 950, 1072],
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          alignItems: ['center', 'center', 'flex-start'],
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
