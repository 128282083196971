import { IModuleMarginProps } from './ModuleSection'
import { theme } from '../../../styles'

export const getMargins = (margin?: IModuleMarginProps, mobileMargin?: IModuleMarginProps) => {
  const { top: mTop, bottom: mBottom, left: mLeft, right: mRight } = margin || {}
  const { top: mobileMTop, bottom: mobileMBottom, left: mobileMLeft, right: mobileMRight } = mobileMargin || {}

  const desktopMarginTop = mTop && theme.v2.moduleMargin[mTop]
  const desktopMarginBottom = mBottom && theme.v2.moduleMargin[mBottom]
  const desktopMarginLeft = mLeft && theme.v2.moduleMargin[mLeft]
  const desktopMarginRight = mRight && theme.v2.moduleMargin[mRight]
  const mobileMarginTop = mobileMTop && theme.v2.moduleMargin[mobileMTop]
  const mobileMarginBottom = mobileMBottom && theme.v2.moduleMargin[mobileMBottom]
  const mobileMarginLeft = mobileMLeft && theme.v2.moduleMargin[mobileMLeft]
  const mobileMarginRight = mobileMRight && theme.v2.moduleMargin[mobileMRight]

  const marginTop = (desktopMarginTop || mobileMarginTop) && [
    mobileMarginTop || desktopMarginTop,
    mobileMarginTop || desktopMarginTop,
    desktopMarginTop,
  ]
  const marginBottom = (desktopMarginBottom || mobileMarginBottom) && [
    mobileMarginBottom || desktopMarginBottom,
    mobileMarginBottom || desktopMarginBottom,
    desktopMarginBottom,
  ]
  const marginLeft = (desktopMarginLeft || mobileMarginLeft) && [
    mobileMarginLeft || desktopMarginLeft,
    mobileMarginLeft || desktopMarginLeft,
    desktopMarginLeft,
  ]
  const marginRight = (desktopMarginRight || mobileMarginRight) && [
    mobileMarginRight || desktopMarginRight,
    mobileMarginRight || desktopMarginRight,
    desktopMarginRight,
  ]

  return {
    ...(marginTop ? { marginTop } : {}),
    ...(marginBottom ? { marginBottom } : {}),
    ...(marginLeft ? { marginLeft } : {}),
    ...(marginRight ? { marginRight } : {}),
  }
}
