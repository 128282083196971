import React from 'react'

import {
  Base,
  Landing,
  OurMission,
  TrustedBy,
  Pricing,
  CoreFeatures,
  BranchlessBanking,
  // Support,
  // Integrations,
  SubAccounts,
} from '../components'
import { WebPageStructuredData, StructuredData, PAGES } from '../utils'
import { LazyLoadBlock } from '../shared'
import { theme, Text } from '../shared'
import { Box } from 'rebass'
import { BillboardWithContentCards } from '../shared/components/v2/BillboardWithContentCards/BillboardWithContentCards'
import { homeSupportBillboardWithContentCardsProps, homeLogosBlockProps } from '../data/pages/homepage.data'
import { LogosBlock } from '../shared/components/v2/LogosBlock/LogosBlock'

const App = (): JSX.Element => {
  const HOME = PAGES.HOME

  const textStyle = {
    color: theme.colors.blackPrimary,
    fontFamily: theme.fonts.grtskExa,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    letterSpacing: '-0.16px',
    fontStyle: 'normal',
    marginBottom: '15px',
  }

  return (
    <Base
      headKey={HOME.HEAD_KEY}
      title={HOME.TITLE}
      metaDescription={HOME.DESCRIPTION}
      customFooterBody={
        <Box sx={{ marginTop: ['0px', '0px', '10px', '10px', '10px'] }}>
          <Text tag={'p'} sx={{ ...textStyle }}>{`¹ Based on user data.`}</Text>
          <Text tag={'p'} sx={{ ...textStyle }}>
            {'² Wire payments subject to $15 fee. See our Deposit Account Agreement for details.'}
          </Text>
          <Text tag={'p'} sx={{ ...textStyle }}>
            {
              '³ The NorthOne Mastercard® Small Business Debit Card is issued by The Bancorp Bank, N.A. pursuant to license by Mastercard International Incorporated and may be used everywhere Debit Mastercard is accepted. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.'
            }
          </Text>
          <Text tag={'p'} sx={{ ...textStyle }}>
            {'⁴ See details about mobile check deposits in our Deposit Account Agreement.'}
          </Text>
          <Text tag={'p'} sx={{ ...textStyle }}>
            {
              '⁵ Cash transfers are not true deposits. Additional ATM fees may be charged by 3rd party ATMs or ATM owners. Subject to terms and conditions of Green Dot Corporation.'
            }
          </Text>
          <Text tag={'p'} sx={{ ...textStyle }}>
            {
              '⁶ Our Customer Care Team is here to help Monday to Friday, 8AM to 9PM ET, and Saturday to Sunday, 9AM to 6PM ET (excluding December 25th). Send us a message through the mobile app chat, our website, or email us at support@northone.com.'
            }
          </Text>
          <Text tag={'p'} sx={{ ...textStyle }}>
            {
              '⁷ NorthOne has a Monthly Account Fee. There are additional fees for sending and receiving domestic wires. See the Deposit Account Agreement for details.'
            }
          </Text>
        </Box>
      }
    >
      <WebPageStructuredData
        name={HOME.TITLE}
        description={HOME.DESCRIPTION}
        path={HOME.PATH}
        breadcrumbs={HOME.BREADCRUMBS}
      />
      <StructuredData schemaTypes={['Organization']} />
      <Landing />
      <OurMission />
      <TrustedBy />
      <LazyLoadBlock>
        <CoreFeatures />
      </LazyLoadBlock>
      <LazyLoadBlock>
        <BranchlessBanking />
      </LazyLoadBlock>
      <LazyLoadBlock>
        <SubAccounts />
      </LazyLoadBlock>
      <LazyLoadBlock>
        <LogosBlock {...homeLogosBlockProps} />
      </LazyLoadBlock>
      <LazyLoadBlock>
        <BillboardWithContentCards {...homeSupportBillboardWithContentCardsProps} />
      </LazyLoadBlock>
      <LazyLoadBlock>
        <Pricing />
      </LazyLoadBlock>
    </Base>
  )
}

export default App
