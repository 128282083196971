import React from 'react'
import { Box, SxStyleProp } from 'rebass'
import { contentCardsContainerStyles } from './BillboardWithContentCards.styles'
import { HeadingWithSupportingCopy } from '../HeadingWithSupportingCopy/HeadingWithSupportingCopy'
import { getContainerStyles, getOuterContainerStyles } from '../Billboard/Billboard.styles'
import { ContentCard, IContentCardProps } from '../ContentCard/ContentCard'
import { ModuleSection, IModuleSectionProps } from '../ModuleSection/ModuleSection'
import { ICMSTextV2Props, TextV2 as Text } from '../Text/TextV2'

export interface IBillboardWithContentCardsProps extends IModuleSectionProps {
  contentCards: IContentCardProps[]
  title: ICMSTextV2Props
  body?: ICMSTextV2Props[]
  eyebrowText?: ICMSTextV2Props
  copyLocation?: 'left' | 'right'
  sx?: SxStyleProp
  dataTestId?: string
}

export const BillboardWithContentCards = ({
  contentCards,
  copyLocation,
  eyebrowText,
  title,
  body,
  dataTestId,
  sx,
  ...moduleSectionProps
}: IBillboardWithContentCardsProps) => {
  const isCopyRight = copyLocation === 'right'

  return (
    <ModuleSection {...moduleSectionProps}>
      <Box
        sx={{
          ...getOuterContainerStyles(isCopyRight),
          ...sx,
        }}
      >
        <Box sx={getContainerStyles(isCopyRight)}>
          {eyebrowText && (
            <Text
              tag={eyebrowText.tag}
              textFontStyle={eyebrowText.textFontStyle}
              textFontColor={eyebrowText.textFontColor}
              sx={{ marginBottom: '30px', textTransform: 'uppercase' }}
              data-test-id={dataTestId}
            >
              {eyebrowText.text}
            </Text>
          )}
          <HeadingWithSupportingCopy title={{ ...title, sx: { marginBottom: '20px' } }} body={body} />
        </Box>
        <Box sx={contentCardsContainerStyles}>
          {contentCards.map((contentCard, index) => (
            <ContentCard key={`contentCard-${index}`} {...contentCard} />
          ))}
        </Box>
      </Box>
    </ModuleSection>
  )
}
