import { IBillboardWithContentCardsProps } from '../../shared/components/v2/BillboardWithContentCards/BillboardWithContentCards'
import { ModuleBackgroundColors, ModulePadding } from '../../shared/components/v2/ModuleSection/ModuleSection'
import { TEXT_FONT_COLOR, TEXT_FONT_STYLE, TEXT_TAG } from '../../shared/components/v2/Text/TextV2'
import { ILogosBlockProps } from '../../shared/components/v2/LogosBlock/LogosBlock'

export const homeSupportBillboardWithContentCardsProps: IBillboardWithContentCardsProps = {
  moduleBackgroundColor: { desktop: ModuleBackgroundColors.white },
  modulePadding: { top: ModulePadding.L, bottom: ModulePadding.L },
  mobileModulePadding: { top: ModulePadding.MD, bottom: ModulePadding.MD },
  dataTestId: 'home-support-billboard-with-content-cards-1',
  copyLocation: 'left',
  eyebrowText: {
    tag: TEXT_TAG.h5,
    text: 'Customer Care',
    textFontStyle: TEXT_FONT_STYLE.eyebrow,
    textFontColor: TEXT_FONT_COLOR.copperDark,
  },
  title: {
    tag: TEXT_TAG.h2,
    text: 'Instant support from real human beings<sup>6</sup>',
    textFontStyle: TEXT_FONT_STYLE.h2,
    textFontColor: TEXT_FONT_COLOR.blackPrimary,
  },
  body: [
    {
      tag: TEXT_TAG.p,
      text: 'Talk to a local customer support expert who understands your business.',
      textFontStyle: TEXT_FONT_STYLE.bodyLargeLight,
      textFontColor: TEXT_FONT_COLOR.blackPrimary,
    },
    {
      tag: TEXT_TAG.p,
      text: 'Want to see how quickly we’ll answer?\n Click the chat box in the corner and say hello.',
      textFontStyle: TEXT_FONT_STYLE.bodyLargeLight,
      textFontColor: TEXT_FONT_COLOR.blackPrimary,
    },
  ],
  contentCards: [
    {
      title: {
        tag: TEXT_TAG.h4,
        text: 'Live chat',
        textFontStyle: TEXT_FONT_STYLE.h4,
        textFontColor: TEXT_FONT_COLOR.blackPrimary,
      },
      body: {
        tag: TEXT_TAG.p,
        text: 'Talk to the team through our app or website.',
        textFontStyle: TEXT_FONT_STYLE.bodyMedium,
        textFontColor: TEXT_FONT_COLOR.blackPrimary,
      },
      imageSrc: '/static/v2/illustrations/can-telephone.png',
      imageAlt: 'Can Telephone',
      onClick: (): void => {
        if (window.Intercom) {
          window.Intercom('show')
        }
      },
    },
    {
      title: {
        tag: TEXT_TAG.h4,
        text: 'Phone',
        textFontStyle: TEXT_FONT_STYLE.h4,
        textFontColor: TEXT_FONT_COLOR.blackPrimary,
      },
      body: {
        tag: TEXT_TAG.p,
        text: 'Never wait on hold again. We’ll call you.',
        textFontStyle: TEXT_FONT_STYLE.bodyMedium,
        textFontColor: TEXT_FONT_COLOR.blackPrimary,
      },
      imageSrc: '/static/v2/illustrations/phone.png',
      imageAlt: 'Phone',
    },
    {
      title: {
        tag: TEXT_TAG.h4,
        text: 'Email',
        textFontStyle: TEXT_FONT_STYLE.h4,
        textFontColor: TEXT_FONT_COLOR.blackPrimary,
      },
      body: {
        tag: TEXT_TAG.p,
        text: 'Expect a fast, human response.',
        textFontStyle: TEXT_FONT_STYLE.bodyMedium,
        textFontColor: TEXT_FONT_COLOR.blackPrimary,
      },
      imageSrc: '/static/v2/illustrations/mailbox.png',
      imageAlt: 'Mailbox',
      link: 'mailto:support@northone.com',
    },
  ],
}

const path = '/static/v2/logos'
export const homeLogosBlockProps: ILogosBlockProps = {
  moduleBackgroundColor: {
    desktop: ModuleBackgroundColors.white,
    mobile: ModuleBackgroundColors.white,
  },
  modulePadding: { top: ModulePadding.MD, bottom: ModulePadding.MD },
  dataTestId: 'integrations-logos-block',
  eyebrowText: {
    tag: TEXT_TAG.h5,
    text: 'INTEGRATIONS',
    textFontStyle: TEXT_FONT_STYLE.eyebrow,
    textFontColor: TEXT_FONT_COLOR.copperDark,
  },
  title: {
    tag: TEXT_TAG.h2,
    text: 'Connect to the apps your business uses every day',
    textFontStyle: TEXT_FONT_STYLE.h2,
    textFontColor: TEXT_FONT_COLOR.blackPrimary,
  },
  logos: [
    {
      src: `${path}/paypal-logo.png`,
      altText: 'PayPal Logo',
      order: 1,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/stripe-logo.png`,
      altText: 'Stripe Logo',
      order: 2,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/square-logo.png`,
      altText: 'Square Logo',
      order: 3,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/etsy-logo.png`,
      altText: 'Etsy Logo',
      order: 4,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/airbnb-logo.png`,
      altText: 'Airbnb Logo',
      order: 5,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/amazon-logo.png`,
      altText: 'Amazon Logo',
      order: 6,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/venmo-logo.png`,
      altText: 'Venmo Logo',
      order: 7,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/toast-logo.png`,
      altText: 'Toast Logo',
      order: 8,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/uber-logo.png`,
      altText: 'Uber Logo',
      order: 9,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/lyft-logo.png`,
      altText: 'Lyft Logo',
      order: 10,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/cash-app-logo.png`,
      altText: 'Cash App',
      order: 11,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/shopify-logo.png`,
      altText: 'Shopify Logo',
      order: 12,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/intuit-quickbooks-logo.png`,
      altText: 'Intuit Quickbooks Logo',
      order: 13,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/wave-logo.png`,
      altText: 'Wave Logo',
      order: 14,
      sx: {
        width: '150px',
      },
    },
    {
      src: `${path}/gusto-logo.png`,
      altText: 'Gusto Logo',
      order: 15,
      sx: {
        width: '150px',
      },
    },
  ],
}
